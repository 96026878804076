import { Badge, Collapse, Typography } from "antd";
import { parse } from "date-fns";
import { compareAsc } from "date-fns/esm";
import { graphql, PageProps } from "gatsby";
import * as React from "react";
import Page from "../components/page";

const { Title } = Typography;

interface EventItemBadgeProps {
  targetDate: string;
}

const EventItemPanelBadge = (props: EventItemBadgeProps) => {
  const date = parse(props.targetDate, "yyyy-MM-dd", new Date());
  const isDone = compareAsc(date, new Date()) < 0;
  return isDone ? (
    <Badge count="終了" style={{ backgroundColor: "gray" }} />
  ) : (
    <Badge count="開催予定" style={{ backgroundColor: "red" }} />
  );
};

const EventsPage = (props: PageProps<Queries.EventPageQuery>) => (
  <Page title="朝鮮カラオケ大会 | イベント">
    <Title>イベント</Title>
    <Collapse defaultActiveKey={["event_55"]} bordered={false}>
      {props.data.allMarkdownRemark.edges.map((it) => (
        <Collapse.Panel
          header={it.node.frontmatter?.title || "Unknown Title"}
          extra={
            <EventItemPanelBadge
              targetDate={it.node.frontmatter?.target_date || "Unknown Date"}
            />
          }
          key={it.node.frontmatter?.id || "error_unknown_key"}
        >
          <div
            dangerouslySetInnerHTML={{ __html: it.node.html || "No Data" }}
          />
        </Collapse.Panel>
      ))}
    </Collapse>
  </Page>
);

export default EventsPage;

export const query = graphql`
  query EventPage {
    allMarkdownRemark(
      filter: { frontmatter: { category: { in: "event" } } }
      sort: { fields: [frontmatter___target_date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            category
            target_date(formatString: "yyyy-MM-DD")
            title
            id
          }
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
